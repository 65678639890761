<template>
  <div class="mobile-reg">

    <!-- 注册头部组件 -->
    <DowApp />
    <Header :loginState="loginState" />
    <div class="input-bg">
      <div class="input-box">
        <p class="tlt-top">{{$t('loginRegister.loginTitle1')}}<a href="/login">{{$t('loginRegister.loginTitle2')}}</a></p>
        <!-- 注册左边栏组件 -->
        <RegLeft />
        <div class="input-fr">
          <div v-if="regState == 1" class="input-fr-box">
            <h2>{{$t('loginRegister.mobileRegRightTitle1')}}</h2>           
            <div class="from-box">
              <mobile-input v-model="phone" :countryList="countryList" :errShowList="errShowList" @mobileBlur="inputBlur"  @country="selectCountry" @inputVal="inputPhoneVal" />
              <code-input v-model="phoneCode" :verCodeData="verCodeData" :errCodeList="errCodeList" @codeBlur="phoneCodeBlur()"  @getVerCodeEmit="getPhoneCode"  @onPhoneCode="codeVal"/>             
              <!-- <div @click="invitCodefun" class="invit-code">
                <p>{{$t('loginRegister.loginTitle5')}}</p>
                <img v-if="invitCodeShow" src="@/assets/images/transaction/xs-icon.png" alt="">
                <img v-else src="@/assets/images/transaction/xx-icon.png" alt="">
              </div>
              <InvitCodeInput v-if="invitCodeShow" @inputInvitCodeVal="inputInvitCodeVal" :errInvitCodeList="errInvitCodeList"></InvitCodeInput> -->
              <div class="input-title">
                <p>{{$t('loginRegister.mobileRegRightTitle2')}}</p>
              </div>
              <div class="xieyi">
                <div @click="checkAgree" class="check-box" :class="{checkActive:checkState,notCheckActive:notCheck}"></div>
                <p><span>{{$t('loginRegister.loginTitle6')}}</span><a :href="links1">{{$t('loginRegister.agreement')}}</a></p>                 
              </div>
              <input @click="regLogin" class="input-btn" type="button" :value="$t('loginRegister.regLogin')" />
            </div>            
            <div class="input-fr-bottom">
              <div v-if="regModeState" class="title-box">
                <div class="fgx"></div>
                <p>OR</p>
                <div class="fgx"></div>
              </div>
              <!-- <a v-if="regModeState" class="link-btn" href="/emailReg">
                <img class="emyx-icon" src="../assets/images/register/emyx-icon.png" alt="" />
                <span>E-mail</span>
              </a> -->
              <div class="other-ways">
                <a class="fb-sign-in" href="/emailReg"><img src="@/assets/images/register/s-email.png" alt=""></a>
                <a class="fb-sign-in" @click="appleLogin"><img src="@/assets/images/register/s-apple.png" alt=""></a>
                <a class="fb-sign-in" @click="googleLogin"><img src="@/assets/images/register/s-google.png" alt=""></a> 
                <a class="fb-sign-in" @click="faceBookLogin"><img src="@/assets/images/register/s-facebook.png" alt=""></a>
                <a class="fb-sign-in" :href="'https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=1657871404&redirect_uri='+lineUrl+'&state='+randomNumber+'&scope=profile%20openid%20email'" ><img src="@/assets/images/register/s-line.png" alt=""></a>
              </div>             
            </div>
          </div>

          <!-- 注册成功组件 -->
          <reg-success v-else />
        </div>
      </div>
      <!-- 底部组件 -->
      <LoginFooter />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DowApp from "@/components/DowApp.vue";
import Header from "@/components/Header.vue";
import LoginFooter from "@/components/LoginFooter.vue";
import RegLeft from "@/components/RegLeft.vue";
import MobileInput from '@/components/MobileInput.vue';
import CodeInput from '@/components/CodeInput.vue';
import InvitCodeInput from '@/components/InvitCodeInput.vue';
import RegSuccess from '@/components/RegSuccess.vue';
import account from '@/api/account.js';
import Dialog from '@/components/Dialog.vue';
import axios from "axios";
import { setCookie, getCookie } from '@/util/cookie';
import {Config, HEADER_USER_TOKEN} from '@/util/const.js';
import {initializeApp} from "firebase/app";
import {GoogleAuthProvider,FacebookAuthProvider,OAuthProvider,getAuth,signInWithPopup} from "firebase/auth";

export default {
  name: "MobileReg",
  components: {
    DowApp,
    Header,
    links1:'',
    LoginFooter,
    RegLeft,
    MobileInput,
    CodeInput,
    InvitCodeInput,
    RegSuccess,
    Dialog
  },
  data() {
     return {
      domainUrl:'',
      regState: 1,
      regModeState:false,
      loginState:1,
      invitCodeShow:false,
      checkState:false,
      notCheck:false,
      country_name:'',
      userId:'',
      randomNumber:'',
      lineUrl:'',
      displayName  :'',
      email:'',
      invitCode:'',
      photoURL:'',
      loadUrl:'',
      platform:'',
      isShowConfirm:false,
      verCodeData: {
        isVerCode: '0',
        showTime:60,
        interval:'',
      }, 
      countryList: {
        countrys: [],
        countryActive:'',
      },
      countryCurrent:[],
      phone:'',
      phoneCode:'',
      errShowList: {
        errShow:false,
        errContent:'',
      },
      errCodeList: {
        errShow:false,
        errContent:'',
      },
      errInvitCodeList: {
        errShow:false,
        errContent:'',
      },
      seoSource:''    
    }
  },
  created() {
    this.domainUrl = window.location.host;
    Config.userToken = getCookie(HEADER_USER_TOKEN);
    console.log(Config.userToken+"Config.userToken");
    let _this = this;
    if (Config.userToken){
      this.$router.push({ path: '/' });
    }
    var firebaseConfig = {
      apiKey: "AIzaSyB0fyWEfc0EDBiLxOt0G2ovVGfVCIg_zBw",
      authDomain: "cmtrade-d4d38.firebaseapp.com",
      projectId: "cmtrade-d4d38",
      storageBucket: "cmtrade-d4d38.appspot.com",
      messagingSenderId: "249891653115",
      appId: "1:249891653115:web:9c86f8b29b27bd673ab75e",
      measurementId: "G-TCTH1LREZJ"
    };
  
    initializeApp(firebaseConfig);

    // line登录注册
    let date = Date.now();
    let rund = Math.ceil(Math.random()*1000)
    this.randomNumber = date + '' + rund;

    var lineStr= window.location.href.split("?")[0];
    this.lineUrl = encodeURIComponent(lineStr);
    
    const lineCode = this.$route.query.code;
    const lineState = this.$route.query.state;
    if(lineCode){
      axios({ 
        method: 'post',
        withCredentials: false,
        url: 'https://api.line.me/oauth2/v2.1/token',
        data:'grant_type=authorization_code&code='+lineCode+'&redirect_uri='+this.lineUrl+'&client_id=1657871404&client_secret=30a093c0e246783252f0a0c9b3a59aaf',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(function (res) {
        console.info("token:res{}",res);
        if(res.status == 200){
          console.info("idtoken:res{}",res.data.id_token);
          axios({ 
              withCredentials: false,
              method: 'post',
              url: 'https://api.line.me/oauth2/v2.1/verify',
              data:'id_token='+res.data.id_token+'&client_id=1657871404',                           
              }).then(function (results) {
                console.info("results:{}",results);
                if(results.status != 200){
                    return;
                }
                var email = results.data.email;
                var uid = results.data.sub;
                _this.userId = uid;
                _this.displayName = results.data.name;
                _this.email = email;
                _this.photoURL = results.data.picture;
                _this.platform = 'Line';
                _this.loginByThird();
              }).catch(function (error) {
                console.log(error);
              });
            } 
      }).catch(function (error) {
        console.log(error);
      });
    }
    //根据当前国家ip地址，获取当前国家id
    account.getCurrentCountryByIp().then((res)=>{
     this.country_name = res.data.value.country_name;
     console.log(this.country_name+"ip");
    }).catch(err=>{
      console.log(err);
    })

    // 获取手机号注册的国家下拉列表数据
    account.getSupportCountry({"type":"mobile"}).then((res)=>{
     this.countryList.countrys = res.data.value
     if(this.country_name == 'Vietnam' || this.country_name == 'Indonesia' || this.country_name == 'Taiwan' || this.country_name == 'Thailand'){

      this.countryList.countryActive = this.country_name;

     }else{
      this.countryList.countryActive = res.data.value[0].countryId //默认选中第一个国家
     }
     
     let countryCurrent = this.countryList.countrys.filter(it => it.countryId == this.countryList.countryActive);//帅选选中的国家数据
     this.countryCurrent = countryCurrent;
    }).catch(err=>{
      console.log(err.data.message);
    })

     // 获取邮箱注册的国家下拉列表数据,如有国家数据就显示对应的注册方式
    account.getSupportCountry({"type":"email"}).then((res)=>{
        if(res.data.value == ""){
            this.regModeState = false;
        }else{
          this.regModeState = true;
        }
    }).catch(err=>{
      console.log(err.data.message);
    })
    let lang = getCookie('LOCALE');
      if(lang == "zh_CN"){
          lang = "ch";
      }else if(lang == "zh_TW"){
          lang = "tw"
      }else if(lang == "en_US"){
          lang = "en"
      }else if(lang == "id_ID"){
          lang = "id"
      }else if(lang == "th_TH"){
          lang = "th"
      }else if(lang == "hi_IN"){
          lang = "hi"
      }
    // let domain= window.location.host.split(".").length > 2 ? window.location.host.split(".")[1]+"."+window.location.host.split(".")[2] : window.location.host ;
    // this.links1 = window.location.protocol +"//www."+domain+"/"+lang+"/agreement";
    this.links1 = window.location.protocol +"//www.cmtrade.com/"+lang+"/agreement";
       
    //seo推广utm_source统计渠道来源
    const utm_source = this.$route.query.utm_source;
    if(utm_source){
      this.seoSource = utm_source
    }else{
      if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
        this.seoSource = "SEO_REGISTERMOBILE";
        } else{
          this.seoSource = "SEO_REGISTERPC";
        }      
    }
  },
  methods: {

     // Apple登录注册
    appleLogin(){
      let _this = this;
      const provider = new OAuthProvider('apple.com');
      const auth = getAuth();        
      signInWithPopup(auth, provider)
        .then((result) => {
          console.log("appleresult",result);
          // The signed-in user info.
          const user = result.user;

          // Apple credential
          const credential = OAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;
          _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'Apple';
          _this.loginByThird();
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);
          console.log('error',error);      
          // ...
        });
    },

    // faceBook登录注册
    faceBookLogin(){
      let _this = this;
      const provider = new FacebookAuthProvider();
      const auth = getAuth();        
      signInWithPopup(auth, provider)
        .then((result) => {
          // The signed-in user info.
          const user = result.user;

          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
          _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'FaceBook';
          _this.loginByThird();
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);
          // ...
        });
    },

    // google登录注册
    googleLogin(){
      let _this = this;
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      // provider.addScope('https://www.googleapis.com/auth/admin.directory.customer');
      
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
           _this.userId = result.user.providerData[0].uid;
          _this.displayName = result.user.displayName;
          _this.email = result.user.email;
          _this.photoURL = result.user.photoURL;
          _this.platform = 'Google'; 
          _this.loginByThird();
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    },

    // 第三方注册接口
    loginByThird(){
      let data = {
        email: this.email,
        userId: this.userId,
        displayName: this.displayName,
        photoURL:this.photoURL,
        platform:this.platform,
        loadUrl: getCookie("loadUrl") || window.location.href,
        channel:this.seoSource,
        domainUrl:this.domainUrl
        // channel: getCookie("utm_source") || 'pc_web'   
      }
      account.loginByThird(data).then((res)=>{
        if(res.data.success == 1){ 
          setCookie(HEADER_USER_TOKEN, res.data.value.userToken, 7)
          Config.userToken = res.data.value.userToken;
          if(res.data.value.isNew){
              this.regState = 0;
          }else{
              this.$router.push({ path: '/' })
          }                 
        }else{
          const popTitle = res.data.message;//多语言传入的文字
          const cancel = this.$i18n.t('perfectData.cancel');
          const determine = this.$i18n.t('perfectData.determine');
          const title = this.$i18n.t('accountCenter.reminder');
          this.isShowConfirm = true
          this.$refs.myConfirm.show(popTitle, {
              type: 'alert',
              cancelText:cancel,
              confirmText: determine,
              titleText: title,
              data: '我是外界传进来的数据'
          })
          console.log(res.data.message);
        }
      }).catch(err=>{
        
      })
    },

    // 获取选中的国家及国家区码
    selectCountry(val){
      let countryCurrent = this.countryList.countrys.filter(it => it.countryId == val);//帅选选中的国家数据
      this.countryCurrent = countryCurrent;
      console.info("countryCurrent",this.countryCurrent[0].countryCallingCode);
    },

    //选中同意客户协议
    checkAgree(){
      this.checkState = !this.checkState;
    },

    //显示隐藏邀请码
    invitCodefun(){
        this.invitCodeShow = !this.invitCodeShow;
    },

    // 获取输入的手机号
    inputPhoneVal(val){
      this.phone = val
      console.log(this.phone);
    },

    // 获取输入的验证码
    codeVal(val){
      this.phoneCode = val
      console.log(this.phoneCode);
    },
    // 获取输入的邀请码
    inputInvitCodeVal(val){
      this.invitCode = val
      console.log(this.invitCode);
    },
    // 鼠标离开输入框校验不能为空
    inputBlur(val){
      if(val == "mobile"){
        const regPhone = eval(this.countryCurrent[0].frontendRegex);
        console.log("frontendRegex"+regPhone);
        if(!this.phone){
          this.errShowList.errShow = true;
          this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4');       
        }else{
          if(!regPhone.test(this.phone)){
            this.errShowList.errShow = true;
            this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5');             
          }else{
            this.errShowList.errShow = false;
          }
        }          
      }
    },  
    phoneCodeBlur(){
      if(!this.phoneCode){
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
      }else{
        this.errCodeList.errShow = false;
      }
    },

    // 获取手机验证码
    getPhoneCode(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4'); 
        return; 
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
        return;  
      }else{
        this.errShowList.errShow = false;
      }
      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        countryId: this.countryCurrent[0].countryId,
        phone: this.phone,
        type: 'mobile',
        domainUrl:this.domainUrl
      }
      account.phoneCode(data).then((res)=>{
        if(res.data.success == 1){
          let _this = this;
          _this.verCodeData.isVerCode = 1;
          _this.verCodeData.interval = setInterval(function(){
            _this.verCodeData.showTime--;
            if(_this.verCodeData.showTime < 1){
              clearInterval(_this.verCodeData.interval);
              _this.verCodeData.interval ="";
              _this.verCodeData.showTime = 60;
              _this.verCodeData.isVerCode = 0;
            }
          },1000);
        }else{
          this.errShowList.errShow = true;
          this.errShowList.errContent = res.data.message; 
        }     
      }).catch(err=>{
        console.log(err.message);
      })
      this.errShowList.errShow = false;   
    },

    // 手机号注册/登录
    regLogin(){
      const regPhone = eval(this.countryCurrent[0].frontendRegex);
      console.log("frontendRegex"+regPhone);
      if(!this.phone){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt4'); 
        return;
      }
      if(!regPhone.test(this.phone)){
        this.errShowList.errShow = true;
        this.errShowList.errContent = this.$i18n.t('loginRegister.checkPrompt5'); 
        return;
      }else{
        this.errShowList.errShow = false;
      }
      if(!this.phoneCode){
        this.errShowList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = this.$i18n.t('loginRegister.checkPrompt6');
        return;
      }else{
        this.errCodeList.errShow = false;
      }
      if(!this.checkState){
        this.notCheck = true;
        return;
      }else{
        this.notCheck = false;
      }

      let data = {
        countryCode: this.countryCurrent[0].countryCallingCode,
        countryId: this.countryCurrent[0].countryId,
        mobile: this.phone,
        verCode: this.phoneCode,
        invitCode: this.invitCode,
        loadUrl: getCookie("loadUrl") || window.location.href,
        channel: this.seoSource,
        domainUrl:this.domainUrl
        // channel: getCookie("utm_source") || 'pc_web',
      }
      account.phoneReg(data).then((res)=>{
        if(res.data.success == 1){
          setCookie(HEADER_USER_TOKEN, res.data.value.userToken, 7)
          Config.userToken = res.data.value.userToken;
          if(res.data.value.isNew){
            this.regState = 0;
            this.loginState = 2;
          }else{
            this.$router.push({ path: '/' })
          }
          
        }else{
          this.errShowList.errShow = false;
          this.errCodeList.errShow = true;
          this.errCodeList.errContent = res.data.message;
          console.log(res.data.message);
        }
      }).catch(err=>{
        this.errShowList.errShow = false;
        this.errCodeList.errShow = true;
        this.errCodeList.errContent = err.message;
      })       
       
    },
    // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      if(type === "clickCancel"){
        // that.$router.push("./");//去账户首页
        this.isShowConfirm = false    
      }else if(type === "clickConfirm"){
        // that.$router.push("./");
        this.isShowConfirm = false    
      }          
    }, 
  }
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
