<template>
  <div class="input-fl hide-pc" :style="{'background-image': 'url('+bgImg+')','background-repeat': 'no-repeat','background-size': '100% 100%'}">
    <h2 class="title1">{{$t('loginRegister.regLeftTitle1')}}</h2>
    <p class="title2">{{$t('loginRegister.regLeftTitle2')}}</p>
    <img class="xf1-img" src="../assets/images/register/$600.png" alt="">
    <img class="xf2-img" src="../assets/images/register/$1200.png" alt="">
    <img class="xf3-img" src="../assets/images/register/$2400.png" alt="">
  </div>
</template>

<script>
export default{
  data() {
    return {
      bgImg:''
    }
  },
  created() {
   this.bgImg = require("../assets/images/register/fl-bg2_"+this.$i18n.locale+".png");
  },
  methods: {
    
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .input-fl{width: 516px;height: 100%;position: relative; background-size: 100% 100%;}
  .input-fl .title1{font-size: 26px;line-height: 27px;margin-top: 48px; color: #fff;font-weight: 200;text-align: center;}
  .input-fl .title2{font-size: 16px;margin-top: 15px;padding: 0 20px;color: #fff;font-weight: 400;text-align: center;}
  .input-fl .xf1-img{position: absolute;top:220px;right: 85px;}
  .input-fl .xf2-img{position: absolute;top:275px;left: 75px;}
  .input-fl .xf3-img{position: absolute;bottom:90px;right: 85px;}
</style>
